import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select",
      "style": {
        "position": "relative"
      }
    }}>{`Select`}</h1>
    <p>{`É um tipo de `}<em parentName="p">{`input`}</em>{` muito utilizado em formulários, onde o usuário está submetendo informações e precisa escolher apenas uma opção de uma lista. Quando o usuário insere algum texto no campo de input, as sugestões são filtradas. É importante apresentar opções de escolha quando ativo.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`A ordem dos itens da lista deve ser baseada na frequência de uso e, se aplicável, em ordem crescente.`}</li>
      <li parentName="ul">{`Caso seja necessário selecionar mais de uma opção, utilize o `}<a parentName="li" {...{
          "href": "/components/multi-select"
        }}>{`Multi Select`}</a>{`.`}</li>
      <li parentName="ul">{`Evite o uso do `}<em parentName="li">{`select`}</em>{` para `}<em parentName="li">{`input`}</em>{` de dados que são conhecidos pelo usuário, como datas de nascimento.`}</li>
    </ul>
    <h2 {...{
      "id": "exemplo",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplo`}</h2>
    <Demo src='pages/components/select/Select' mdxType="Demo" />
    <h2 {...{
      "id": "sugestao",
      "style": {
        "position": "relative"
      }
    }}>{`Sugestão`}</h2>
    <p>{`Permita que o valor digitado seja o que o usuário digitar, fornecendo uma propriedade `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createNewItem`}</code>{`.`}</p>
    <p>{`Você pode usar a propriedade `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openOnFocus`}</code>{` para controlar se o menu de sugestões deve aparecer quando a entrada receber o foco - o padrão é falso para as seleções de sugestões (quando createNewItem) e verdadeiro para as seleções normais.`}</p>
    <Demo src='pages/components/select/Suggestion' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      